import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultGD, IDefaultDataGD, IDefaultDataAddGD, IDefaultDataUpdateGD } from '../models/defaultGD';
import { GestionDispositivosPSService } from '../services/gestionDispositivosPS.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-procseg',
  templateUrl: './procseg.component.html',
  styleUrls: ['./procseg.component.scss']
})

export class ProcsegComponent {
  @ViewChild('dt1') dt!: Table;
  lstProcSeg: Array<IDefaultDataGD> = [];
  dataGetIt: IDefaultGD = { status: false, data: []};
  loading: boolean = false;
  dspProc: boolean = false;
  ProcsForm!: FormGroup;
  texto_operacion: string = '';
  num_operacion: number = 0;
  color_gris_deshabilitadas: string = '#D9D9D9';
  fecha_dia: string = new Date().toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' });

  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private gestionDispositivosService: GestionDispositivosPSService,private auth: AuthService) {
      this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeProcesosDeSeguridad();
    this.crearFormularioProcesoSeguridad();
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  getListaDeProcesosDeSeguridad() {
    this.loading=true;
    this.gestionDispositivosService.getListadoProcesos().subscribe((resp) => {
      if (resp.status) {
        this.lstProcSeg = resp.data
      }

      this.loading=false;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Procesos de seguridad');
    });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  crearFormularioProcesoSeguridad() {
    this.ProcsForm = new FormGroup({
      id: new FormControl(),
      nombre: new FormControl('', [
        Validators.required
      ],
      ),
      id_procs: new FormControl({ value: 0, disabled: true }, [],),
      estado: new FormControl()
    });
  }

  guardarInformacionDeProcesoSeguridad() {
    if (this.num_operacion == 1) {
      let data_enviar: IDefaultDataAddGD = {
        name: this.ProcsForm.get('nombre')?.value,
        desc: '',
        user: this.user.cod == undefined ? '0001' : this.user.cod
      };

      this.gestionDispositivosService.saveProcesoSeguridad(data_enviar).subscribe((resp) => {
        if (resp.status) {
          setTimeout(() => {
            this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
            this.ProcsForm.reset();
            this.cancelarVentanaModal();
            this.getListaDeProcesosDeSeguridad();
          }, 800);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar ProcesoSeguridad>', detail: 'Contacte al Administrador del Sitio' });
        }
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar ProcesoSeguridad>', detail: 'Contacte al Administrador del Sitio' });
        });
    } else
      if (this.num_operacion == 2) {
        let data_enviar: IDefaultDataUpdateGD = {
          id: this.ProcsForm.get('id')?.value,
          name: this.ProcsForm.get('nombre')?.value,
          desc: '',
          user: this.user.cod == undefined ? '0001' : this.user.cod,
          state: this.ProcsForm.get('estado')?.value
        };

        this.gestionDispositivosService.editProcesoSeguridad(data_enviar).subscribe((resp) => {
          if (resp.status) {
            setTimeout(() => {
              this.messageService.add({ severity: 'success', summary: 'Registro Actualizado', detail: 'La informacion se actualizo de forma correcta' });
              this.ProcsForm.reset();
              this.cancelarVentanaModal();
              this.getListaDeProcesosDeSeguridad();
            }, 800);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error en <Editar ProcesoSeguridad>', detail: 'Contacte al Administrador del Sitio' });
          }
        },
          (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error en <Editar ProcesoSeguridad>', detail: 'Contacte al Administrador del Sitio' });
          });
      }
  }

  mostrarModalAgregar() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspProc = true;
    this.texto_operacion = 'Agregar Nuevo Proceso de Seguridad';
  }

  mostrarModalEditar(proceso: IDefaultDataGD) {
    this.ProcsForm.controls['id'].setValue(proceso.id);
    this.ProcsForm.controls['nombre'].setValue(proceso.nombre);
    this.num_operacion = 2;
    this.dspProc = true;
    this.texto_operacion = 'Editar Proceso de Seguridad';
  }

  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspProc = false;
    this.texto_operacion = '';
    this.ProcsForm.reset();
  }

  confirmarEliminar(dataDel: IDefaultDataGD) {
   let estatus_original= dataDel.estado;
    let texto_desactivar: string = dataDel.estado == 0 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = dataDel.estado == 0 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el registro del Proceso Seguridad ' + dataDel.nombre + '?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        const data: IDefaultDataUpdateGD = {  id: 0, name: '', desc: '', user: '', state: 0 }
        data.id = dataDel.id
        data.state = dataDel.estado == 1 ? 0 : 1
        data.user = this.user.cod == undefined ? '0001' : this.user.cod,

        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            dataDel.estado = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            dataDel.estado = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: IDefaultDataUpdateGD) {
    this.gestionDispositivosService.desactivarProcesoSeguridad(data).subscribe((resp) => {
      if (resp.status) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ProcsForm.reset();
          this.cancelarVentanaModal();
          this.getListaDeProcesosDeSeguridad();
        }, 800);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Proceso de Seguridad>', detail: 'Contacte al Administrador del Sitio' });
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Proceso de Seguridad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }

}
