<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Gestion De Dispositivos</header>
          </div>
          <br />
          <br />
          <div style="display: inline-block;">
            <div class="row pb-2" style="display: flex; align-items: baseline;">
              <div class="col-md-4">
                <div class="card">
                  <p-dropdown [options]="lstUnits" [(ngModel)]="selectedUnit" autoWidth="false"
                    [style]="{'width':'100%'}" [filter]="true" filterBy="nombre" optionValue="id" optionLabel="nombre"
                    [showClear]="true" placeholder="Seleccione Numero Economico*"></p-dropdown>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card sinborde">
                  <button id="button_search_unit" type="button" class="btn btn-primary" [style]="{'width':'50%'}"
                    (click)="searchUnitAction();">
                    Buscar
                  </button>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card sinborde">
                  <button id="button_search_unit" type="button" pButton pRipple class="p-button-success ml-5" [style]="{'width':'50%'}"
                    (click)="exportarExcel();">
                    <i class="pi pi-file-excel"> Exportar</i>
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div id="spinner_searching_unit" style="display: none;">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p-progressSpinner ariaLabel="loading" />
              </div>
            </div>
            <div id="contenido_unidad_elementos" style="display: none;">
              <h6><b>Tipo Transporte:</b> {{unitType}}</h6>
              <h6><b>Numero Economico:</b> {{unitNumEco}}</h6>
              <h6><b>Ciudad:</b> {{unitCity}}</h6>

              <div class="card-content">
                <div class="row mt-3">
                  <p-table #dt1 [value]="lstUnitSelected" [tableStyle]="{'min-width': '50rem'}"
                    styleClass="p-datatable-sm p-datatable-striped" [rows]="100" [globalFilterFields]="['herramienta']"
                    [showCurrentPageReport]="true" [resizableColumns]="false"
                    currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros">
                    <ng-template pTemplate="caption">
                      <div class="flex justify-content-between">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                            placeholder="Buscar herramienta" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Herramienta</th>
                        <th>Proveedor</th>
                        <th class="text-center">Instalada
                        <th class="text-center">Funcionamiento
                        <th class="text-center">Configurada
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-index>
                      <tr>
                        <td>{{data.herramienta}}</td>
                        <td>{{data.proveedor}}</td>
                        <td class="text-center">
                          <ng-container *ngIf="data.clv_instalado != null; else templateclv_instalado">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_instalado == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 1)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 1)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_instalado}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_instalado}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_instalado>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 1)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 1)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>

                        <td class="text-center">
                          <ng-container *ngIf="data.clv_funcionamiento != null; else templateclv_funcionamiento">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_funcionamiento == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 2)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 2)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_funcionamiento}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_funcionamiento}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_funcionamiento>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 2)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 2)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>

                        <td class="text-center">
                          <ng-container *ngIf="data.clv_configuracion != null; else templateclv_configuracion">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_configuracion == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_configuracion}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_configuracion}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_configuracion>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
              <br /><br /><br />
            </div>
          </div>
          <p-dialog [resizable]="false" [draggable]="false" [(visible)]="confirmarSeleccionDispositivo"
            [style]="{ width: '50vw' }" (onHide)="ocultarDialogConfirmacion()">
            <ng-template pTemplate="content">
              <div class="mt-5">
                <h3 class="text-center">¿Estas Seguro que deseas guardar la Informacion?</h3>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text"
                (click)="ocultarDialogConfirmacion()"></button>
              <button pButton label="Continuar" icon="pi pi-check" class="p-button-success"
                (click)="updateEstadoDelDispositivo()"></button>
            </ng-template>
          </p-dialog>
        </div>
      </div>
    </div>
  </div>
</div>
