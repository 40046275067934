<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000"
acceptLabel="Aceptar" rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Proveedores de dispositivos</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-primary form-control"
                                    (click)="mostrarModalAgregar();" pTooltip="Agregar proveedor"
                                    tooltipPosition="top">
                                    Agregar <i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstProv" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['nombre']" [paginator]="true"
                                [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                                [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-between">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="id" class="text-center">#Proveedor<p-sortIcon
                                                field="id"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre" class="text-center">Nombre del proveedor<p-sortIcon
                                                field="nombre"></p-sortIcon></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr [ngClass]="{'row-accessories': data.estado === 1}">
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.id}}</p>
                                        </td>
                                        <td class="text-center">{{data.nombre}}</td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning"
                                                    pTooltip="Editar informacion del proveedor" tooltipPosition="left"
                                                    (click)="mostrarModalEditar(data);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Estado del proveedor"
                                                    (click)="confirmarEliminar(data)">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="mt-3 mb-2">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas" [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Proveedor Inactivo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspProv"
        [style]="{width: '60vw','height':'450px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{ texto_operacion}}
        </ng-template>
        <ng-template pTemplate="content">
          <form class="form_procseg" [formGroup]="ProvForm" (ngSubmit)="guardarInformacionDeProveedores()">
            <div class="ml-1">
                <div class="row mt-5 d-flex justify-content-center">
                    <div class="col-sm-6">
                        <span class="title-light">Informacion del proveedor
                            <i class="pi pi-cog" style="size: 2rem;">
                            </i>
                        </span>
                    </div>
                </div>
                <div class="row mt-2 d-flex justify-content-center">
                    <div class="form-group col-md-6">
                        <div class="row g-0">
                            <div class="col-sm-1 mx-0">
                                <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                        style="font-size: 2rem"></i>
                                </span>
                            </div>
                            <div class="col-sm-11">
                                <input type="hidden" class="form-control sinborde" formControlName="id" >
                                <input type="text" class="form-control sinborde"
                                    placeholder="Nombre del proveedor*" formControlName="nombre">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center mt-3">
                    <div class="col-4">
                        <p *ngIf="!ProvForm.valid" class="texto-invalido text-right">
                            <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                La Informaci&oacute;n Obligatoria(*)</i>
                        </p>
                        <p *ngIf="ProvForm.valid" class="texto-valido text-right">
                            <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                Completa</i>
                        </p>
                    </div>
                    <div class="col-2">
                        <button class="btn btn-primary form-control" type="submit"
                            [disabled]="!ProvForm.valid" pTooltip="Almacenar informacion"
                            tooltipPosition="right">
                            <span class="text-white">Guardar</span>
                            <i class="pi pi-save text-white"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>
</div>
