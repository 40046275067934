import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultDataGDToolsUnitTransport, IDefaultLessDataGD, IDefaultDataUpdateCheck } from '../models/defaultGD';
import { GestionDispositivosToolsUnitTransportService } from '../services/gestionDispositivosToolsUnitTransport.service';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-gestdisp',
  templateUrl: './gestdisp.component.html',
  styleUrls: ['./gestdisp.component.scss']
})

export class GestdispComponent {
  @ViewChild('dt1') dt!: Table;
  lstUnits: IDefaultLessDataGD[] = [];
  lstUnitSelected: IDefaultDataGDToolsUnitTransport[] = [];
  dspProc: boolean = false;
  selectedUnit: String = '';
  unitType: String = '';
  unitNumEco: String = '';
  unitCity: String = '';
  selectedCheckValue: Boolean = false;
  selectedCheckIdTool: Number = 0;
  selectedCheckIdToolType: Number = 0;
  confirmarSeleccionDispositivo: boolean = false;

  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private gestionDispositivosService: GestionDispositivosToolsUnitTransportService, private auth: AuthService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeUnidadesDisponibles();
  }

  showData() {
    const datosUnidad = document.getElementById('contenido_unidad_elementos')

    if (datosUnidad) {
      datosUnidad.style.display = "block"
    }
  }

  searchUnitAction() {
    if (this.selectedUnit == '' || this.selectedUnit == null) {
      alert('Debes seleccionar una unidad para continuar')
    } else {
      (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = true
      this.progress()
      this.getListaDeHerramientasPorUnidad(this.selectedUnit)
    }
  }

  progress() {
    const datosUnidad = document.getElementById('spinner_searching_unit')

    if (datosUnidad) {
      if (datosUnidad.style.display === "none") {
        datosUnidad.style.display = "block";
      } else {
        datosUnidad.style.display = "none";
      }
    }
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  onChangeChekState(check: boolean, id: number, type: number) {
    this.selectedCheckIdTool = id
    this.selectedCheckValue = check
    this.selectedCheckIdToolType = type

    this.confirmarSeleccionDispositivo = true;
  }

  getListaDeUnidadesDisponibles() {
    this.gestionDispositivosService.getListadoHerramientasPorUnidadSoloNumeroEconomico().subscribe((resp) => {
      if (resp.status) {
        this.lstUnits = resp.data
      }

    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
      });
  }

  getListaDeHerramientasPorUnidad(id: String) {
    this.gestionDispositivosService.getListadoHerramientasPorUnidad(id).subscribe((resp) => {
      if (resp.status) {
        this.lstUnitSelected = resp.data;

        if (resp.data.length > 0) {
          this.unitType = resp.data[0].tipo
          this.unitNumEco = resp.data[0].num_economico
          this.unitCity = resp.data[0].ciudad
        }
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
      });

    this.showData();
    this.progress();
    (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = false
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Verifique la informacion.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  ocultarDialogConfirmacion() {
    this.confirmarSeleccionDispositivo = false;
  }

  updateEstadoDelDispositivo() {
    let data: IDefaultDataUpdateCheck = {
      id: this.selectedCheckIdTool,
      check: this.selectedCheckValue ? 1 : 0,
      user: this.user.cod == undefined ? '0001' : this.user.cod
    }

    if (this.selectedCheckIdToolType == 1) {
      this.gestionDispositivosService.updateActualizarHerramientaInstalada(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }

    if (this.selectedCheckIdToolType == 2) {
      this.gestionDispositivosService.updateActualizarHerramientaFuncionando(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }

    if (this.selectedCheckIdToolType == 3) {
      this.gestionDispositivosService.updateActualizarHerramientaConfigurada(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }
  }

  //Reporte
  exportarExcel() {
    if (!this.lstUnitSelected.length || typeof this.lstUnitSelected === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> Debe consultar un vehiculo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstUnitSelected.map((item) => {
          return {
            "#": item.id,
            NumeroEconomico: item.num_economico,
            Ciudad: item.ciudad,
            TipoTransporte: item.tipo,
            NombreHerramienta: item.herramienta,
            DescripcionHerramienta: item.herramienta_descripcion,

            DispositivoInstalado: item.clv_instalado,
            FechaConfirmacionInstalacion: item.fecha_instalado,
            UsuarioConfirmoInstalado: item.usuario_instalado,

            DispositivoFuncionando: item.clv_funcionamiento,
            FechaConfirmacionFuncionamiento: item.fecha_funcionamiento,
            UsuarioConfirmoFuncionamiento: item.usuario_funcionamiento,

            DispositivoConfigurado: item.clv_configuracion,
            FechaConfirmacionConfiguracion: item.fecha_configuracion,
            UsuarioConfirmoConfiguracion: item.usuario_configuracion
          }
        }));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "GestionHerramientasYDispositivos_"+this.unitNumEco);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_" + dateFormat + EXCEL_EXTENSION);
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
}
