import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IDefaultGD, IDefaultGDTUT, IDefaultGDLess, IDefaultDataUpdateCheck } from '../models/defaultGD';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GestionDispositivosToolsUnitTransportService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.sUrlGd + 'tool';
  }

  getListadoHerramientasPorUnidadAll(): Observable<IDefaultGDTUT> {
    return this.http
      .get<IDefaultGDTUT>(this.apiUrl + '/unit/all')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHerramientasPorUnidad(id: String): Observable<IDefaultGDTUT> {
    return this.http
      .get<IDefaultGDTUT>(this.apiUrl + '/unit/by?id=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHerramientasPorUnidadSoloNumeroEconomico(): Observable<IDefaultGDLess> {
    return this.http
      .get<IDefaultGDLess>(this.apiUrl + '/unit/less')
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateActualizarHerramientaInstalada(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/installed', body,{'headers':headers});
   }

   public updateActualizarHerramientaConfigurada(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/configured', body,{'headers':headers});
   }

   public updateActualizarHerramientaFuncionando(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/working', body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
