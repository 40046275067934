import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultGDDT, IDefaultLessDataGD, IDefaultDataGD, IDefaultDataGDDT, IDefaultDataAddGDDT, IDefaultDataUpdateGD } from '../models/defaultGD';
import { GestionDispositivosDeviceToolsService } from '../services/gestionDispositivosDeviceTools.service';
import { GestionDispositivosSupplierService } from '../services/gestionDispositivosSupplier.service';
import { GestionDispositivosToolsService } from '../services/gestionDispositivosTools.service';
import { GestionDispositivosPSService } from '../services/gestionDispositivosPS.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ITipoTransporte } from '../models/tipotransporte';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-herramconc',
  templateUrl: './herramconc.component.html',
  styleUrls: ['./herramconc.component.scss']
})

export class HerramconcComponent {
  @ViewChild('dt1') dt!: Table;
  lstTool: IDefaultDataGDDT[] = [];
  dataGetIt: IDefaultGDDT = { status: false, data: [], id: '' };
  loading: boolean = false;
  dspProc: boolean = false;
  ToolForm!: FormGroup;
  texto_operacion: string = '';
  num_operacion: number = 0;
  color_gris_deshabilitadas: string = '#D9D9D9';
  fecha_dia: string = new Date().toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' });
  // Listados precargados
  lstTransporte: ITipoTransporte[] = [{ clave_tipoTransporte: 1, nombre: "LIGERO" }, { clave_tipoTransporte: 2, nombre: "PESADO" }];
  lstTools: IDefaultLessDataGD[] = [];
  lstProviders: IDefaultLessDataGD[] = [];
  lstProcSeg: IDefaultLessDataGD[] = [];


  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private gestionDispositivosServiceDT: GestionDispositivosDeviceToolsService,
    private gestionDispositivosServiceSupplier: GestionDispositivosSupplierService,
    private gestionDispositivosServiceTools: GestionDispositivosToolsService,
    private gestionDispositivosServicePS: GestionDispositivosPSService,
    private auth: AuthService) { this.cargarInformacionUsuario(); }

  ngOnInit(): void {
    this.getListaDeHerramientaConcentrado();
    this.crearFormularioHerramienta();
    this.cargarListadoProveedores();
    this.cargarListadoHerramientas();
    this.cargarListadoProcSeg();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  getListaDeHerramientaConcentrado() {
    this.loading = true;
    this.gestionDispositivosServiceDT.getListadoHerramientas().subscribe((resp) => {
      if (resp.status) {
        this.lstTool = resp.data
      }

      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas Concentrado');
      });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  crearFormularioHerramienta() {
    this.ToolForm = new FormGroup({
      id: new FormControl(),
      clave_tipoTransporte: new FormControl('', [Validators.required],),
      id_prov: new FormControl('', [Validators.required],),
      id_her: new FormControl('', [Validators.required],),
      id_proc: new FormControl('', [Validators.required],),
      estado: new FormControl()
    });
  }

  guardarInformacionDeHerramienta() {
    let data_enviar: IDefaultDataAddGDDT = {
      id_typet: this.ToolForm.get('clave_tipoTransporte')?.value,
      id_supp: this.ToolForm.get('id_prov')?.value,
      id_tool: this.ToolForm.get('id_her')?.value,
      id_procs: this.ToolForm.get('id_proc')?.value,
      user: this.user.cod == undefined ? '0001' : this.user.cod
    };

    this.gestionDispositivosServiceDT.saveHerramienta(data_enviar).subscribe((resp) => {
      if (resp.status) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ToolForm.reset();
          this.cancelarVentanaModal();
          this.getListaDeHerramientaConcentrado();
        }, 800);
      } else {
        this.messageService.add({ severity: 'info', summary: 'Aviso', detail: `Ya existe ese registro #${resp.id}, favor de verificar la informacion` });
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Herramienta Concentrado>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarModalAgregar() {
    this.cancelarVentanaModal();
    this.num_operacion = 1;
    this.dspProc = true;
    this.texto_operacion = 'Agregar Nueva Herramienta Concentrado';
  }

  cancelarVentanaModal() {
    this.num_operacion = 0;
    this.dspProc = false;
    this.texto_operacion = '';
    this.ToolForm.reset();
  }

  confirmarEliminar(dataDel: IDefaultDataGD) {
    let estatus_original = dataDel.estado;
    let texto_desactivar: string = dataDel.estado == 0 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = dataDel.estado == 0 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el registro del Herramienta ' + dataDel.nombre + '?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        const data: IDefaultDataUpdateGD = { id: 0, name: '', desc: '', user: '', state: 0 }
        data.id = dataDel.id
        data.state = dataDel.estado == 1 ? 0 : 1
        data.user = this.user.cod == undefined ? '0001' : this.user.cod,

          this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            dataDel.estado = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            dataDel.estado = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: IDefaultDataUpdateGD) {
    this.gestionDispositivosServiceDT.desactivarHerramienta(data).subscribe((resp) => {
      if (resp.status) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ToolForm.reset();
          this.cancelarVentanaModal();
          this.getListaDeHerramientaConcentrado();
        }, 800);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Herramienta>', detail: 'Contacte al Administrador del Sitio' });
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Editar Herramienta>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  cargarListadoProveedores() {
    this.gestionDispositivosServiceSupplier.getListadoProveedoresLess().subscribe((resp) => {
      if (resp.status) {
        this.lstProviders = resp.data
      }

      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
      });
  }

  cargarListadoHerramientas() {
    this.gestionDispositivosServiceTools.getListadoHerramientasLess().subscribe((resp) => {
      if (resp.status) {
        this.lstTools = resp.data
      }

      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
      });
  }

  cargarListadoProcSeg() {
    this.gestionDispositivosServicePS.getListadoProcesosLess().subscribe((resp) => {
      if (resp.status) {
        this.lstProcSeg = resp.data
      }

      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
      });
  }
}
