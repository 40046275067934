
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultGDTUT, IDefaultDataGDToolsUnitTransport } from '../models/defaultGD';
import { GestionDispositivosToolsUnitTransportService } from '../services/gestionDispositivosToolsUnitTransport.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { empty } from 'rxjs';

@Component({
  selector: 'app-herramunid',
  templateUrl: './herramunid.component.html',
  styleUrls: ['./herramunid.component.scss']
})

export class HerramunidComponent {
  @ViewChild('dt1') dt!: Table;
  lstTool: IDefaultDataGDToolsUnitTransport[] = [];
  loading: boolean = false;
  dspProc: boolean = false;
  color_gris_deshabilitadas: string = '#D9D9D9';

  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private gestionDispositivosService: GestionDispositivosToolsUnitTransportService,private auth: AuthService) {
      this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeHerramienta();
  }

  cargarInformacionUsuario(){
    this.user = this.auth.user;
  }

  getListaDeHerramienta() {
    this.loading=true;
    this.gestionDispositivosService.getListadoHerramientasPorUnidadAll().subscribe((resp) => {
      if (resp.status) {
        this.lstTool = resp.data
      }

      this.loading=false;
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
    });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario:string){
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({key: 'msj',severity:'info', summary:'Confirmación', detail:'Usuario informado de la situación.'});
      },
      key: "sinInformacionDialog"
  });
  }

  exportarExcel() {
    if (!this.lstTool.length || typeof this.lstTool === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> Debe consultar un vehiculo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstTool.map((item) => {
          return {
            "#": item.id,
            NumeroEconomico: item.num_economico,
            Ciudad: item.ciudad,
            TipoTransporte: item.tipo,
            NombreHerramienta: item.herramienta,

            DispositivoInstalado: item.clv_instalado != null ? item.clv_instalado == 0 ? 'NO' : 'SI' : 'Pendiente',
            DispositivoFuncionando: item.clv_funcionamiento != null ? item.clv_funcionamiento == 0 ? 'NO' : 'SI' : 'Pendiente',
            DispositivoConfigurado: item.clv_configuracion != null ? item.clv_configuracion == 0 ? 'NO' : 'SI' : 'Pendiente',
          }
        }));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "GestionHerramientasYDispositivos");
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_" + dateFormat + EXCEL_EXTENSION);
  }
}
