import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IDefaultGD, IDefaultGDLess, IDefaultDataAddGD, IDefaultDataUpdateGD } from '../models/defaultGD';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestionDispositivosSupplierService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.sUrlGd + 'supplier';
  }

  getListadoProveedores(): Observable<IDefaultGD> {
    return this.http
      .get<IDefaultGD>(this.apiUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoProveedoresLess(): Observable<IDefaultGDLess> {
    return this.http
      .get<IDefaultGDLess>(this.apiUrl + '?available=y')
      .pipe(retry(1), catchError(this.handleError));
  }

  public editProveedores(data: IDefaultDataUpdateGD ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl, body,{'headers':headers});
   }

   public desactivarProveedores(data: IDefaultDataUpdateGD ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/disable', body,{'headers':headers});
   }

   public saveProveedores(data: IDefaultDataAddGD ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl, body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
