<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Herramientas Concentrado</header>
            <div class="row d-flex justify-content-end">
              <div class="col-2">
                <button type="button" class="btn btn-primary form-control" (click)="mostrarModalAgregar();"
                  pTooltip="nuevo herramienta" tooltipPosition="top">
                  Agregar <i class="pi pi-plus-circle"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstTool" [tableStyle]="{'min-width': '50rem'}"
                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                responsiveLayout="scroll" [globalFilterFields]="['nombre', 'herramienta', 'descripcion']" [paginator]="true" [rows]="30"
                [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true" [resizableColumns]="false"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-between">
                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Buscar proveedor, herramienta, funcionalidad" />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="id">#<p-sortIcon field="id"></p-sortIcon></th>
                    <th pSortableColumn="nombre">Tipo Transporte<p-sortIcon field="nombre"></p-sortIcon>
                      <th pSortableColumn="nombre">Proveedor<p-sortIcon field="nombre"></p-sortIcon>
                    </th>
                    <th pSortableColumn="herramienta">Herramienta<p-sortIcon
                        field="herramienta"></p-sortIcon></th>
                    <th pSortableColumn="descripcion">Funcionalidad<p-sortIcon
                        field="descripcion"></p-sortIcon></th>
                    <th pSortableColumn="proceso">Proceso<p-sortIcon field="proceso"></p-sortIcon>
                    </th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index>
                  <tr [ngClass]="{'row-accessories': data.estado === 1}">
                    <td>
                      <p class="font-weight-bold text-center">{{data.id}}</p>
                    </td>
                    <td>{{data.tipo}}</td>
                    <td>{{data.nombre}}</td>
                    <td>{{data.herramienta}}</td>
                    <td>{{data.descripcion}}</td>
                    <td>{{data.proceso}}</td>
                    <td>
                      <div class="d-flex justify-content-around">
                        <button class="btn btn-info" pTooltip="Estado de la herramienta" tooltipPosition="top"
                          (click)="confirmarEliminar(data)">
                          <i class="pi pi-delete-left"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="row mt-3 d-flex justify-content-center">
              <div class="col-6">
                <div class="mt-3 mb-2">
                  <p-colorPicker [(ngModel)]="color_gris_deshabilitadas" [disabled]="true"></p-colorPicker>
                  <p class="text-left informativo" style="display: inline;">
                    Herramienta Concentrado Inactiva</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspProc" [style]="{width: '60vw','height':'650px'}"
    [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      {{ texto_operacion}}
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_procseg" [formGroup]="ToolForm" (ngSubmit)="guardarInformacionDeHerramienta()">
        <div class="ml-1">
          <input type="hidden" class="form-control sinborde" formControlName="id" >
          <br />
          <b>Tipo de transporte</b>
          <br />
          <p-dropdown [options]="lstTransporte" autoWidth="false" [style]="{'width':'100%'}" formControlName="clave_tipoTransporte"
            optionValue="clave_tipoTransporte" optionLabel="nombre" [showClear]="true" placeholder="Seleccione Tipo Transporte*"></p-dropdown>
          <br />
          <b>Proveedor</b>
          <br />
          <p-dropdown [options]="lstProviders" autoWidth="false" [style]="{'width':'100%'}" formControlName="id_prov"
            optionValue="id_prov" optionLabel="nombre" [showClear]="true" placeholder="Seleccione Proveedor*"></p-dropdown>
          <br />
          <br />
          <b>Herramienta</b>
          <br />
          <p-dropdown [options]="lstTools" autoWidth="false" [style]="{'width':'100%'}" formControlName="id_her"
            optionValue="id_her" optionLabel="nombre" [showClear]="true" placeholder="Seleccione Herramienta*"></p-dropdown>
          <br />
          <br />
          <b>Proceso Seguridad</b>
          <br />
          <p-dropdown [options]="lstProcSeg" autoWidth="false" [style]="{'width':'100%'}" formControlName="id_proc"
            optionValue="id_proc" optionLabel="nombre" [showClear]="true" placeholder="Seleccione Proceso De Seguridad*"></p-dropdown>

          <div class="row d-flex justify-content-center mt-3">
            <div class="col-4">
              <p *ngIf="!ToolForm.valid" class="texto-invalido text-right">
                <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                  La Informaci&oacute;n Obligatoria(*)</i>
              </p>
              <p *ngIf="ToolForm.valid" class="texto-valido text-right">
                <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                  Completa</i>
              </p>
            </div>
            <div class="col-2">
              <button class="btn btn-primary form-control" type="submit" [disabled]="!ToolForm.valid"
                pTooltip="Almacenar informacion" tooltipPosition="right">
                <span class="text-white">Guardar</span>
                <i class="pi pi-save text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarVentanaModal()"></button>
    </ng-template>
  </p-dialog>
</div>
